<template>
  <aside class="aside">
    <p class="aside__title">
      {{ _T("@TOP products") }}
    </p>
    <ul class="aside__products">
      <MinifiedProduct
        v-for="product in products"
        :key="product.id"
        :product="product"
        :class="product.getExtra('purchaseOrigin')"
      />
    </ul>
  </aside>
</template>

<script setup>
import MinifiedProduct from "~/modules/shared/productCard/MinifiedProduct.vue";
import { useHeaderStore } from "~/store/headerStore";
import { setProductsPurchaseOrigin } from "~/utils/product.utils";

const props = defineProps({
  products: { type: Array, required: true },
});

const headerStore = useHeaderStore();
const { getHeightHeader } = storeToRefs(headerStore);

const headerHeight = () => {
  return getHeightHeader.value.header;
};

watch(
  () => props.products,
  () => {
    setProductsPurchaseOrigin(props.products, "top");
  },
  { immediate: true, deep: true },
);
</script>

<style scoped lang="scss">
.aside {
  position: sticky;
  top: v-bind("headerHeight()");

  max-width: 272px;
  width: 100%;

  @include flex-container(column, flex-start, flex-start);
  gap: 24px;

  padding: 16px;

  &__title {
    @include font(18, 24, 600);
  }

  &__products {
    @include flex-container(column, flex-start, flex-start);
    gap: 8px;
  }
}
</style>
